<template>
  <div
    class="w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-80"
  >
    <!-- <h1 class="uppercase font-semibold text-3xl">
         Welcome to the Virtual Rooms
      </h1> -->
    <img
      v-if="!ready"
      src="/pwa/images/icons/icon-512x512.png"
      alt="Virtual Rooms Logo"
      class="w-80 rounded-xl mx-auto pulse border border-blue-300"
    />

    <h2
      v-else
      class="quote animate-charcter absolute bottom-20 fixed"
      style="bottom: 43%"
    >
      {{ currentText }}
      <span aria-hidden="true"></span>
    </h2>
  </div>
</template>

<script>
import { loginFromSkin, signupFromSkin } from "@/api/authRepository";
import userRepository from "@/api/userRepository";
import settingsRepository from "@/api/settingsRepository";
import tokensRepository from "@/api/tokensRepository";
import { getCredit } from "@/api/creditRepository";
import analyticsConfig from "../mixins/analyticsConfig.vue";
import { LoginError } from "@/common/errorTypes.js";

export default {
  name: "Auth",

  mixins: [analyticsConfig],

  data() {
    return {
      currentText: "",
      textArray: [
        "Preparandosi a viaggiare attraverso i bar d'Italia... ",
        "Personalizzando l'aspetto del tuo avatar con stile...",
        "Accendendo le luci... ",
        "Pulendo i bar... ",
        "Contando i soldi... ",
        "Programmando eventi speciali...",
        "Accendendo le slot machine... ",
        "Aggiornando il sistema di prenotazione...",
        "Accessorizzando il tuo avatar con accessori alla moda...",
        "Creando l'ambiente perfetto...",
        "Calibrando le bobine...",
        "Completando l'outfit del tuo avatar con calzature alla moda...",
        "Configurando le vincite automatiche per favorire la fortuna......",
        "Preparando il sistema di pagamento per le scommesse sulle slot machine...",
        "Rifornendo di bevande e snack per i clienti mentre giocano...",
        "Impostando le opzioni di gioco per un divertimento personalizzato...",
        "Sincronizzando i grafici per un'esperienza visivamente sorprendente...",
        "Preparando i premi speciali per i fortunati vincitori...",
      ],
      i: 0,
      j: 0,
      ready: false,
    };
  },

  created() {
    this.checkIfIsSkinAuthenticated();
    this.$on("polling", this.pollingHandler);
  },

  mounted() {
    this.changeText();
  },

  methods: {
    pollingHandler() {
      this.ready = true;
    },
    changeText() {
      if (this.j < this.textArray[this.i].length) {
        this.currentText += this.textArray[this.i][this.j];
        this.j++;
        setTimeout(() => {
          this.changeText();
        }, 80);
      } else if (this.i < this.textArray.length) {
        setTimeout(() => {
          this.currentText = "";
          this.i++;
          this.j = 0;
          this.changeText();
        }, 1750);
      }
    },

    async checkIfIsSkinAuthenticated() {
      const params = new URLSearchParams(window.location.search);
      const sessionId = params.get("sessionId");
      const skin = params.get("skin");

      if (!!sessionId) {
        let obj = { sessionId };
        if (!!skin) obj = { ...obj, skin };
        try {
          console.log("soy yooooo");
          await this.$store.dispatch("auth/loginUser", obj);

          if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
            this.$store.dispatch("socket/init");
          }

          ////////////////////////////////////////////////////////////
          // this.userIdAnalyticsConfig(user.data.nickname);
          ////////////////////////////////////////////////////////////

          this.$router.push({ name: "SlotsBar" });
        } catch (err) {
          if (err instanceof LoginError) {
            this.$router.push({ name: "Login" });
          }
        }
      } else {
        this.$store.dispatch("auth/cleanUpAuth");
        this.$store.dispatch("socket/destroySocket");
        this.$router.push({ name: "Login" });
      }
    },

    signupFromSkinRequest(token) {
      signupFromSkin(token)
        .then((res) => {
          this.authPolling(token);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 302 && !!e.response.data.redirectUrl) {
            window.location.href = e.response.data.redirectUrl;
          }
          if (e.response.status == 404) {
            this.$router.push({ name: "404" });
          }
        });
    },

    authPolling(token, contractNumber = null, skinId = null) {
      const obj = { token, polling: true };
      loginFromSkin(obj)
        .then((res) => {
          this.$store.dispatch("auth/updateAccessToken", res.data.accessToken);
          this.$store.dispatch("auth/updateDeviceId", res.data.deviceId);
          this.$store.dispatch(
            "auth/updateRefreshToken",
            res.data.refreshToken
          );
          this.$store.dispatch("auth/updateUserId", res.data.userId);
          this.$store.dispatch(
            "auth/updateExternalUserId",
            res.data.externalUserId
          );
          this.$store.dispatch("auth/updateIsAuthenticated", true);
          this.$store.commit("auth/mutateNumContract", res.data.numContract);

          let promises = [
            //TODO response.data.userId
            userRepository.getUser(res.data.userId),
            //TODO response.data.userId
            settingsRepository.getSettings(res.data.userId),
            //TODO response.data.userId
            getCredit(res.data.userId),
            tokensRepository.getCredit(res.data.userId),
            userRepository.getAvatar(res.data.userId),
          ];

          Promise.all(promises)
            .then(([user, settings, credit, tokens, avatar]) => {
              // user.data, settings.data, credit.data, avatar.data;
              this.$store.commit("userInfo/mutateUser", user.data);
              this.$store.commit("userInfo/mutateSettings", settings.data);
              this.$store.commit("userInfo/mutateCredit", credit.data);
              this.$store.commit("userInfo/mutateTokens", tokens.data);
              this.$store.commit(
                "userInfo/mutateAvatarCustom",
                avatar.data && avatar.data.photo ? avatar.data.photo : null
              );

              try {
                if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                  this.$store.dispatch("socket/init");
                }
              } catch (err) {
                console.error(err);
              }

              ////////////////////////////////////////////////////////////
              // this.userIdAnalyticsConfig(user.data.nickname);
              ////////////////////////////////////////////////////////////

              this.$router.push({ name: "SlotsBar" });
            })
            .catch((err) => {
              // TODO implement refresh token logic
              this.$store.dispatch("auth/cleanUpAuth");
              this.$store.dispatch("socket/destroySocket");
            });
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$router.push({ name: "404" });
          }
          if (err.response.status == 503) {
            let timeout = 1000;
            setTimeout(() => {
              this.authPolling(token);
            }, timeout);
          }
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
h2.animate-charcter {
  background-image: linear-gradient(
    125deg,
    #54d2ee 0%,
    #232e51 50%,
    #54d2ee 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  /* color: #fff; */
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
  font-size: 3rem;
  @apply font-bold tracking-wide uppercase;

  span {
    padding-left: 0.2rem;
    border-right: 0.06em solid theme(colors.secondary);
    animation: caret 1s steps(1) infinite;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

img {
  animation: pulsate 5s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1); /* Original size */
    box-shadow: 0px 0px 10px 1px #64adf6;
  }
  50% {
    transform: scale(1.1); /* Slightly larger size */
    box-shadow: 0px 0px 30px 3px #64adf6;
  }
  100% {
    transform: scale(1); /* Back to original size */
    box-shadow: 0px 0px 10px 1px #64adf6;
  }
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}
</style>
