var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-80",
    },
    [
      !_vm.ready
        ? _c("img", {
            staticClass: "w-80 rounded-xl mx-auto pulse border border-blue-300",
            attrs: {
              src: "/pwa/images/icons/icon-512x512.png",
              alt: "Virtual Rooms Logo",
            },
          })
        : _c(
            "h2",
            {
              staticClass: "quote animate-charcter absolute bottom-20 fixed",
              staticStyle: { bottom: "43%" },
            },
            [
              _vm._v(" " + _vm._s(_vm.currentText) + " "),
              _c("span", { attrs: { "aria-hidden": "true" } }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }